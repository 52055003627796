// ---------------------------------------------------
//
// Foundation
//
// ---------------------------------------------------

@import 'foundation/variables';
@import 'foundation/mixin';

/**
 * reset
 */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
	vertical-align: bottom;
	max-width:100%;
	height:auto;
}
a:link, a:visited, a:active, a:hover {
	overflow: hidden;
	outline: none;
}

/**
 * micro clearfix
 */
/* For modern browsers */
.cf:before, .cf:after {
	content: "";
	display: table;
}
.cf:after { clear: both; }
/* For IE 6/7 (trigger hasLayout) */
.cf { zoom: 1; }

.clear {
	clear: both;
}

/* body */
body {
	background:#eee;
	color: #282828;
  font-family: "Helvetica Neue",
    Arial,
    "Hiragino Kaku Gothic ProN",
    "Hiragino Sans",
    Meiryo,
    sans-serif;
}
html, body {
	font-size:14px;
	line-height:1.6;
}

a {
	color:#000;
	text-decoration:underline;
}
a:hover {
	text-decoration:none;
}

.an {
	font-family:"futura-pt", sans-serif;
	font-weight:normal;
}

.inner {
	max-width:1000px;
	margin:auto;
}

@media only screen and (min-width:981px) {
	.sp {
		display:none !important;
	}
}
@media only screen and (max-width:980px) {
	html, body {
		font-size:12px;
	}
	.pc {
		display:none !important;
	}
	.inner {
		box-sizing:border-box;
		width:100%;
		padding:0 15px;
	}
}


/**
 * header
 */
// header {
// 	position:fixed;
// 	width:100%;
// 	background-color:#fff;
// }
// header .inner {
// 	position:relative;
// 	padding:10px 0;
// }

// header .logo {
// 	position:absolute;
// 	left:0;
// 	top:10px;
// 	width:102px;
// }

// #nav_inner ul {
// 	text-align:center;
// }
// #nav_inner li {
// 	display:inline-block;
// 	font-size:18px;
// 	margin:0 20px;
// 	vertical-align:bottom;
// }
// #nav_inner li a, #nav_inner li .pull {
// 	display:block;
// 	text-decoration:none;
// }
// #nav_inner li .pull {
// 	position:relative;
// 	background:url(../img/icon_nav_arrow_g.png) no-repeat right center;
// 	background-size:6px auto;
// 	padding-right:12px;
// }
// #nav_inner li .pull .sub {
// 	display:none;
// 	position:absolute;
// 	width:140px;
// 	left:50%;
// 	transform:translateX(-50%);
// }
// #nav_inner li .pull li {
// 	display:block;
// 	margin:0;
// }
// #nav_inner li .pull li a {
// 	box-sizing:border-box;
// 	display:block;
// 	padding:10px;
// 	border:solid 1px #a7a7a7;
// 	border-top:none;
// 	background-color:#fff;
// 	text-align:left;
// 	font-weight:normal;
// 	font-size:13px;
// 	color:#000;
// }
// #nav_inner li .pull li:first-child a {
// 	border-top:solid 1px #a7a7a7;
// }


// @media only screen and (max-width:900px) and (min-width:981px) {
// 	#nav_inner li {
// 		margin:0 10px;
// 	}
// }

// @media only screen and (max-width:1200px) and (min-width:981px) {
// 	header .logo {
// 		display: none !important;
// 	}
// }

@media only screen and (max-width:980px) {
	header .logo {
		position:relative;
		left:15px;
		top:0;
	}

	/* #btn_menu */
	#btn_menu {
		position:absolute;
		width:20px;
		height:17px;
		right:15px;
		top:15px;
		z-index:2000;
	}
	#btn_menu.on {
		position:fixed;
	}
	#btn_menu div {
		position:absolute;
		width:20px;
		height:3px;
		background-color:#747474;
		transition:all 0.2s ease-out
	}
	#btn_menu div:nth-child(2) {
		top:7px;
	}
	#btn_menu div:nth-child(3) {
		top:14px;
	}
	#btn_menu.on div:nth-child(1) {
		transform:rotate(45deg);
		top:7px;
	}
	#btn_menu.on div:nth-child(2) {
		opacity:0;
		transform:rotate(45deg);
	}
	#btn_menu.on div:nth-child(3) {
		transform:rotate(-45deg);
		top:7px;
	}

	// header nav {
	// 	position:fixed;
	// 	width:100%;
	// 	height:100%;
	// 	top:0;
	// 	// left:100%;
	// 	z-index:1000;
	// 	transition:left 0s linear 0.25s;
	// }
	// header nav.on {
	// 	transition:left 0s linear 0s;
	// 	left:0;
	// }
	// #nav_bg {
	// 	position:absolute;
	// 	width:100%;
	// 	height:100%;
	// 	top:0;
	// 	left:0;
	// 	background:rgba(0,0,0,0.5);
	// }

	// #nav_inner {
	// 	box-sizing:border-box;
	// 	position:absolute;
	// 	width:280px;
	// 	height:100%;
	// 	right:-280px;
	// 	background:#fff;
	// 	overflow:auto;
	// 	transition:right 0.2s ease-out;
	// }
	// header nav.on #nav_inner {
	// 	right:0;
	// 	transition:right 0.3s ease-out;
	// }

	// #nav_inner > ul {
	// 	padding:50px 20px 30px 20px;
	// }
	// #nav_inner li {
	// 	display:block;
	// 	border-bottom:solid 1px #c3c3c3;
	// 	margin:0;
	// }
	// #nav_inner li:first-child {
	// 	border-top:solid 1px #c3c3c3;
	// }
	// #nav_inner li a, #nav_inner li .pull {
	// 	padding:10px 0;
	// 	text-align:left;
	// }

	#nav_inner li .pull {
		background:url(../img/icon_pull_off.png) no-repeat right 19px;
		background-size:16px auto;
		padding-right:0;
	}
	#nav_inner li .pull.on {
		background-image:url(../img/icon_pull_on.png);
	}
	#nav_inner li .pull .sub {
		position:static;
		width:100%;
		left:0;
		transform:translateX(0);
		padding:5px 0;
	}
	#nav_inner li .pull li {
		border:none;
	}
	#nav_inner li .pull li a, #nav_inner li .pull li:first-child a {
		border:none;
		padding:5px 0;
	}
}


/**
 * footer
 */
footer {
	padding:50px 0 40px 0;
	background-color:#4db332;
	color:#fff;
}
footer nav {
	float:left;
	margin-left:52px;
	margin-bottom:30px;
}
footer nav h2 {
	margin: 0 0 .7em;
	font-size:15px;
	font-weight:bold;
	line-height: 1.6;
}
footer nav li {
	margin-bottom:.5em;
}
@media only screen and (min-width:980px) {
	footer nav li {
		font-size: 14px;
	}
}
footer nav a {
	display:inline-block;
	color:#fff;
	text-decoration:none;
}
footer nav a:hover {
	text-decoration:underline;
}
footer nav a[target="_blank"] {
	padding-right:20px;
	background:url($pathToImg + "common/icon_blank.png") no-repeat right 3px;
}
footer .copy {
	margin-top:30px;
	text-align:center;
}
@media only screen and (min-width:1001px) {
	footer nav:nth-child(6n+1) {
		margin-left:0;
	}
}
@media only screen and (max-width:1000px) and (min-width:581px) {
	footer nav {
		width:33.33%;
		margin-left:0;
	}
	footer nav:nth-child(3n+1) {
		clear:both;
	}
}
@media only screen and (max-width:580px) {
	footer nav {
		width:50%;
		margin-left:0;
	}
	footer nav:nth-child(2n+1) {
		clear:both;
	}
}

.u-no-link {
	overflow: hidden;
	display: inline-block;
}

